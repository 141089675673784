import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "technical-setup"
    }}>{`Technical setup`}</h1>
    <p>{`The design systen consists of four packages. `}<inlineCode parentName="p">{`design-tokens`}</inlineCode>{`, `}<inlineCode parentName="p">{`react-icons`}</inlineCode>{`, `}<inlineCode parentName="p">{`react-ui-kit`}</inlineCode>{` and `}<inlineCode parentName="p">{`charts`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "design-tokens"
    }}>{`Design tokens`}</h2>
    <p>{`This package contains colors used across all projects.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`@urbaninfrastructure/design-tokens`}</inlineCode>{` from npm:`}</p>
    <p><inlineCode parentName="p">{`npm install @urbaninfrastructure/design-tokens`}</inlineCode></p>
    <h2 {...{
      "id": "react-icons"
    }}>{`React Icons`}</h2>
    <p>{`This package contains a collection of icons as React components.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`@urbaninfrastructure/react-icons`}</inlineCode>{` from NPM by running `}<inlineCode parentName="p">{`yarn add @urbaninfrastructure/react-icons`}</inlineCode>{`.`}</p>
    <p>{`The icon components can then be imported from `}<inlineCode parentName="p">{`@urbaninfrastructure/react-icons`}</inlineCode>{`,
Remember, only import the icons you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {Check as CheckIcon} from '@urbaninfrastructure/react-icons'

function App() (
  <CheckIcon color="primary" />
)
`}</code></pre>
    <h2 {...{
      "id": "react-ui-kit"
    }}>{`React UI Kit`}</h2>
    <p>{`This package is our component library built using React and styled-components.`}</p>
    <h3 {...{
      "id": "installation"
    }}>{`Installation`}</h3>
    <p>{`Install `}<inlineCode parentName="p">{`@urbaninfrastructure/react-ui-kit`}</inlineCode>{` and `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` from npm:`}</p>
    <p><inlineCode parentName="p">{`npm install @urbaninfrastructure/react-ui-kit styled-components`}</inlineCode></p>
    <h3 {...{
      "id": "usage"
    }}>{`Usage`}</h3>
    <p>{`There is no required setup for react-ui-kit, it exposes ready to use components.`}</p>
    <p>{`You can use components in your React app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Button } from "@urbaninfrastructure/react-ui-kit";

const App = () => <Button variant="primary">Hello world!</Button>;

export default App;
`}</code></pre>
    <h2 {...{
      "id": "charts"
    }}>{`Charts`}</h2>
    <p>{`This library is a collection of react chart components.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`@urbaninfrastructure/charts`}</inlineCode>{` from NPM by running `}<inlineCode parentName="p">{`yarn add @urbaninfrastructure/charts`}</inlineCode>{`.`}</p>
    <p>{`Then import the component you want by use:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { BarStackHorizontalSimple } from @urbaninfrastructure/charts

const App = () => <BarStackHorizontalSimple data={[...]} />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      